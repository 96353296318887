@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "jad";
    font-style: normal;
    src: url("Assets/jad.ttf");
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #333333;
}

::-webkit-scrollbar-thumb {
  background-color: #d2042d;
  border-radius: 10px;
  border: 3px solid #333333;
}

::-webkit-scrollbar-thumb:hover {
  background: #1e1e1e;
}

.custom-drop-shadow {
  filter: drop-shadow(0.1px 0.1px 0.3px #d2042d) !important;
}
