// PolicyPage.scss

.policy-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__title {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
  }

  &__content {
    color: #555;
    font-size: 16px;
    line-height: 1.5;
  }

  &:hover {
    transform: scale(1.02);
    transition: transform 0.3s ease-in-out;
  }
}

.bold-text {
  font-weight: 900;
}