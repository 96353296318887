:root {
  font-family: "Montserrat", sans-serif;
}
::selection {
  color: #d2042d;
  background: black;
}
.arrow-icon {
  transition: fill 0.3s ease;
}

button:hover .arrow-path {
  fill: white; /* Change the color to white on hover */
}
